import { Button, NavBar, Tag } from "antd-mobile";
import { useLocation, useNavigate } from "react-router";
import www from "../../services";
import { useEffect, useState, useRef } from "react";
import dayjs from "dayjs";
import MaskLoading from "../../components/maskLoading";
import "./index.scss";
import Countdown from "./countdown";
import QuestionList from "./questionList";
import SubmitExamModal from "./submitExam";
import duration from 'dayjs/plugin/duration';
dayjs.extend(duration);

export default function ExamQuestion({ }) {
    const navigate = useNavigate()
    const { state } = useLocation()
    const [pageData, setPageData] = useState()
    const [loading, setLoading] = useState(false)
    const [answerList, setAnswerList] = useState([])
    const [queIndex, setQueIndx] = useState(1)
    const questionRef = useRef()
    const [countdownTime, setCountdownTime] = useState()
    const [examRecordDetailList, setExamRecordDetailList] = useState([])
    const getExamDetail = async () => {
        setLoading(true)
        const res = await www.get(`/exams/${state.examId}`)
        setPageData(res.data)
        setLoading(false)
    }

    const getCountdown = (e) => {
        setCountdownTime(e)
    }
    const receiveAnswers = (params) => {
        setAnswerList(params?.answers)
        setQueIndx(params?.questionIndex + 1)
    }
    const continueDownTime = () => {
        const examTime = dayjs.duration({ minutes: pageData?.examTime })
        return dayjs().add(examTime - dayjs.duration(state.diff ?? 0))
    }
    const getExamRecordDetail = async () => {
        const re = await www.get(`/examRecord/${state?.examRecordId}`)
        setExamRecordDetailList(re.data.examRecordDetailList)
    }
    useEffect(() => {
        getExamDetail()
        if (!!state.diff) {
            getExamRecordDetail()
        }
    }, [])
    return <section className="page">
        <NavBar
            style={{ color: "white" }}
            onBack={() => navigate(-1)}
            right={
                <SubmitExamModal
                    unanswered={pageData?.examQuestionsAllVOList.length - answerList.length}
                    time={countdownTime}
                    recordId={state?.examRecordId}
                    confrim={questionRef.current?.lastQuestionPushAnswer}
                    trigger={<Tag
                        color="white"
                        style={{
                            '--border-radius': '14px',
                            padding: "6px 15px",
                            color: "white"
                        }}
                        fill='outline'>提交</Tag>} />
            }
        >
            {pageData?.name}
        </NavBar>

        <MaskLoading loading={loading} />
        <NavBar
            className="questionBar"
            backArrow={pageData?.examTime ? <Countdown endTime={continueDownTime()} pushTime={getCountdown} /> : <></>}
            right={<Tag
                color='white'
                style={{
                    '--border-radius': '14px',
                    padding: "6px 15px",
                    boxShadow: "0 0 2px #333",
                    color: "#3c3c3c"
                }}
            >
                {queIndex}/{pageData?.examQuestionsAllVOList.length}
            </Tag>}
        >

        </NavBar>
        <QuestionList
            ref={questionRef}
            recordDetailList={examRecordDetailList}
            questions={pageData?.examQuestionsAllVOList}
            recordId={state?.examRecordId}
            throwAnswers={receiveAnswers}
            examId={pageData?.id}
        />
    </section>
}