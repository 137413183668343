import { NavBar, Image, List, Popup } from "antd-mobile";
import {
  UnorderedListOutline,
  FillinOutline,
  LoopOutline,
} from "antd-mobile-icons";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import www from "../../services";
import "./index.scss";

export default function UserPage() {
  const navigate = useNavigate();
  const userData = JSON.parse(localStorage.getItem("user_data"));

  const [visible, setVisible] = useState(false);
  const [userInfo, setUserInfo] = useState([]);

  const logOut = () => {
    localStorage.clear();
    navigate("/login");
  };

  useEffect(() => {
    const getUserInfo = async () => {
      const params = {
        userId: userData?.userId,
        type: userData?.type,
      };

      const res = await www.get("/userInfo", { params });

      if (res.success) {
        setUserInfo(res.data);
      }
    };

    getUserInfo();
  }, []);

  return (
    <div className="userBox">
      <NavBar backArrow={false} style={{ color: "white" }}>
        个人中心
      </NavBar>

      <div className="userInfo">
        <div className="userInfoLeft">
          <div className="name">{userInfo?.name}</div>
          <div>{userInfo?.position}</div>
          <div>{userInfo?.phone}</div>
        </div>
        <div className="userImg">
          <Image
            src={
              userInfo?.photoUrl
                ? userInfo?.photoUrl
                : "https://images.unsplash.com/photo-1493666438817-866a91353ca9?ixlib=rb-0.3.5&q=80&fm=jpg&crop=faces&fit=crop&h=200&w=200&s=b616b2c5b373a80ffc9636ba24f7a4a9"
            }
            fit="cover"
          />
        </div>
      </div>

      <List mode="card" style={{ margin: 0 }}>
        <List.Item
          prefix={<UnorderedListOutline />}
          onClick={() => navigate("/myInfo")}
        >
          我的资料
        </List.Item>
        <List.Item
          prefix={<FillinOutline />}
          onClick={() => navigate("/editPassword")}
        >
          修改密码
        </List.Item>
        <List.Item prefix={<LoopOutline />} onClick={() => setVisible(true)}>
          退出登录
        </List.Item>
      </List>

      <Popup
        visible={visible}
        onMaskClick={() => {
          setVisible(false);
        }}
        destroyOnClose
        bodyStyle={{
          minHeight: "10vh",
        }}
      >
        <div className="logOutBox">
          <div className="text logOut" onClick={() => logOut()}>
            确定退出
          </div>
          <div className="text" onClick={() => setVisible(false)}>
            取消
          </div>
        </div>
      </Popup>
    </div>
  );
}
