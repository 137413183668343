import "https://g.alicdn.com/IMM/office-js/1.1.15/aliyun-web-office-sdk.min.js";
import { useEffect, useRef } from "react";
import { getToken } from "./service";
import "./index.scss";

const ViewFile = ({ attachmentUrl }) => {
  const ref = useRef(null);

  useEffect(() => {
    if (!attachmentUrl) {
      return;
    }

    (async () => {
      const position = attachmentUrl.indexOf("di-park");
      const projectName = attachmentUrl.substring(position);

      const res = await getToken(projectName);
      const tokenInfo = res.data;
      const webOffice = window.aliyun.config({
        mount: ref.current,
        url: tokenInfo.WebofficeURL,
      });
      webOffice.setToken({
        token: tokenInfo.AccessToken,
        timeout: 25 * 60 * 1000, // Token过期时间，单位为ms。25分钟之后刷新Token。
      });
    })();
  }, [attachmentUrl]);

  return <div ref={ref} id="weboffice-zone"></div>;
};

export default ViewFile;
