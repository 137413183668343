import { TabBar } from "antd-mobile";
import { AppOutline, UserOutline } from "antd-mobile-icons";
import { useNavigate, Outlet, useLocation } from "react-router-dom";
import "./index.scss";

export default function TabBarPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;

  return (
    <div className="tabBarBox">
      <div className="content">
        <Outlet />
      </div>
      <div className="tabBar">
        <TabBar activeKey={pathname} onChange={(value) => navigate(value)}>
          <TabBar.Item key="/tabBar/home" icon={<AppOutline />} title="首页" />
          <TabBar.Item
            key="/tabBar/user"
            icon={<UserOutline />}
            title="个人中心"
          />
        </TabBar>
      </div>
    </div>
  );
}
