
import './App.css';
import RouteViews from './router';
import Vconsole from 'vconsole'

function App() {
  const vConsole = new Vconsole()
  return <RouteViews />
}

export default App;
