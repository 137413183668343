import { NavBar, Button, Form, Input, Toast } from "antd-mobile";
import { useNavigate } from "react-router-dom";
import { useRef } from "react";
import www from "../../services";
import "./index.scss";

export default function EditPasswordPage() {
  const navigate = useNavigate();
  const formRef = useRef(null);
  const userData = JSON.parse(localStorage.getItem("user_data"));

  const onFinish = async (values) => {
    const data = {
      ...values,
      userId: userData?.userId,
      type: userData?.type,
    };

    const res = await www.put("/updatePassword", data);

    if (res.success) {
      Toast.show({
        content: "修改成功",
      });
      navigate(-1);
    }
  };

  return (
    <div className="editPasswordBox">
      <NavBar
        onBack={() => navigate(-1)}
        right={
          <Button
            size="mini"
            color="primary"
            onClick={() => formRef.current.submit()}
          >
            完成
          </Button>
        }
      >
        修改密码
      </NavBar>

      <div className="formBox">
        <Form ref={formRef} layout="horizontal" onFinish={onFinish}>
          <Form.Item
            name="oldPassword"
            rules={[{ required: true, message: "原密码不能为空" }]}
          >
            <Input placeholder="请输入原密码" />
          </Form.Item>
          <Form.Item
            name="newPassword"
            rules={[{ required: true, message: "确定密码不能为空" }]}
          >
            <Input placeholder="请输入确定密码" />
          </Form.Item>
        </Form>
      </div>
    </div>
  );
}
