import { Card, Image, Toast } from "antd-mobile";
import { ContentOutline, TeamOutline } from "antd-mobile-icons";
import "./examinationItem.scss";
import www from "../../services";
import shijuan from "../../assets/shijuan.png";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";

export default function ExaminationItem({
  select = []
}) {
  const navigate = useNavigate()
  const [examinations, setExaminations] = useState([])
  const userData = JSON.parse(localStorage.getItem("user_data"))
  const getExamination = async () => {
    const params = {
      enterpriseId: userData?.enterpriseId,
      userId: userData.userId,
      type: select.join()
    }
    const re = await www.get(`/examRecord/app/examContent`, { params })
    setExaminations(re.data)
  }
  const types = {
    "吊装作业": "1",
    "设备检维修作业": "2",
    "动土作业": "3",
    "受限空间作业": "4",
    "高处作业": "5",
    "动火作业": "6",
    "盲板抽堵作业": "7",
    "断路作业": "8"
  }
  const findExamEligibility = async (item) => {
    const params = {
      enterpriseId: userData?.enterpriseId,
      userId: userData.userId,
      type: types[item.specialWorkType]
    }
    const re = await www.get("/examRecord/app/findExamEligibility", { params })
    if (re.data.eligibility === 2) {
      Toast.show({
        content: '请先学习完资料，再来考试。',
      })

    } else if (re.data.isExam === 1) {
      Toast.show({
        content: '当前有正在进行的考试，无法同时进行两场考试。',
      })
    } else {
      addExamRecord(item.examId)
    }
  }

  const addExamRecord = async (examId) => {
    const { enterpriseId } = userData
    const params = {
      enterpriseId,
      examId
    }
    const re = await www.post("/examRecord", params)
    navigate("/examQuestion", {
      state: {
        examId,
        examRecordId: re.data.id
      }
    })
  }
  useEffect(() => {
    getExamination()
  }, [select])
  return (
    <>
      {examinations?.map((item, index) => (
        <Card
          className="trainingContent"
          key={index}
          onClick={
            () => findExamEligibility(item)
          }
        >
          <div style={{ display: "flex" }}>
            <Image
              src={shijuan}
              fit="cover"
              className="imgItem"
            />

            <div className="content">
              <div className="fileName">
                <ContentOutline style={{ marginRight: "6px" }} />
                <span>{item.examName}</span>
              </div>
              <div style={{ margin: "4px 0" }}>
                <span key="1">{item.specialWorkType}</span>
                <span key="2" style={{ margin: "0 4px" }}>|</span>
                <span key="3">{item.fullScore}</span>
                <span key="4" style={{ margin: "0 4px" }}>|</span>
                <span key="5">{item.passScore}</span>
              </div>
              <div>
                <span style={{ marginRight: "10px" }}>
                  <TeamOutline />
                  {item.resourceReaders}
                </span>
              </div>
            </div>

            <span className="time">限时{item.examTime}分</span>
          </div>
        </Card>
      ))}

      <div style={{ textAlign: "center" }}>没有更多了</div>
    </>
  );
}
