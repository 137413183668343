import { Tag } from "antd-mobile"
import { ExclamationCircleOutline } from "antd-mobile-icons";
import { useEffect, useState } from "react"
import dayjs from "dayjs";
import duration from 'dayjs/plugin/duration'
dayjs.extend(duration);

const Countdown = ({
  endTime,
  pushTime
}) => {
  let intervalId = null;
  const [timeStr, setTimeStr] = useState('')

  // 更新剩余时间的函数
  const updateCountdown = () => {
    // 结束时间转换为Day.js对象
    const end = dayjs(endTime);
    // 如果结束时间是过去的时间，则直接返回
    const now = dayjs();
    if (end.isBefore(now)) {
      setTimeStr('')
      return;
    }
    const difference = end.diff(now); // 计算剩余时间
    let duration = dayjs.duration(difference, 'milliseconds');

    // 格式化时间
    duration.format("HH:mm:ss")
    setTimeStr(duration.format("HH:mm:ss"))
    pushTime?.(duration.format("HH:mm:ss"))
    // 如果时间到了，停止计时
    if (difference <= 0) {
      clearInterval(intervalId);
    }
    if (!intervalId) {
      intervalId = setInterval(updateCountdown, 1000)
    }
  }
  useEffect(() => {
    updateCountdown()
  }, [])
  return <Tag
    color='warning'
    style={{ '--border-radius': '14px', padding: 6 }}
  >
    <ExclamationCircleOutline color='white' />{timeStr}
  </Tag>
}

export default Countdown