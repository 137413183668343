import { Swiper, Tag } from "antd-mobile"
import QuestionOptions from "./questionOptions"
import { forwardRef, useImperativeHandle, useState } from "react"
import www from "../../services"
import { questionTypeData } from "./type"

const QuestionList = forwardRef(({
  questions = [],
  recordDetailList = [],
  recordId,
  throwAnswers,
  examId
}, ref) => {
  const [optionAnswer, setOptionAnswer] = useState([])
  const [swiperIndex, setSwiperIndex] = useState(0)
  useImperativeHandle(ref, () => ({
    lastQuestionPushAnswer: async () => {
      await pushAnswer(swiperIndex)
    }
  }))
  const onSwiperIndexChange = (idx) => {
    /**
     * 往后滑动的才会提交答案
     */
    throwAnswers({ answers: optionAnswer, questionIndex: idx })
    if (idx >= swiperIndex) {
      /**
       * 未作答的不提交 
       */
      const findAnswer = optionAnswer.findIndex(answer => answer.questionId === questions[idx - 1].questionId)
      if (findAnswer !== -1 && !!optionAnswer[findAnswer]?.answer) {
        /**
         * 执行提交
         */
        pushAnswer(findAnswer)
      }
      setSwiperIndex(idx)
    }
  }
  const pushAnswer = async (nowIdx) => {
    if (optionAnswer.length < nowIdx) return;
    const params = {
      recordId,
      questionId: questions[nowIdx].questionId,
      questionScore: questions[nowIdx].score,
      questionType: optionAnswer[nowIdx].type,
    }
    switch (optionAnswer[nowIdx].type) {
      case 1:
      case 2:
        params.userAnswer = optionAnswer[nowIdx].answer.map(id => ({
          answerId: id
        }))
        break;
      case 3:
        params.examId = examId
        params.judgeAnswer = optionAnswer[nowIdx].answer
        break;
      case 4:
        params.userAnswer = optionAnswer[nowIdx].answer.map(a => ({
          answerId: a.id,
          answer: a.answer
        }))
        break;
    }
    const re = await www.post("/examRecordDetail", params)
  }
  const getAnswers = (itemAnswer, questionId) => {
    setOptionAnswer(answer => {
      const answerIndex = answer.findIndex(aw => aw.questionId === questionId)
      if (answerIndex !== -1) {
        answer[answerIndex] = { ...itemAnswer, questionId }
        return [...answer]
      } else {
        return [...answer, { ...itemAnswer, questionId }]
      }
    })
  }
  return <Swiper onIndexChange={onSwiperIndexChange} className={swiperIndex > 0 ? "questionContainer hiddentip" : "questionContainer"} indicator={false}>
    {questions?.map((item, index) => {
      const findQue = recordDetailList.findIndex(record => record.questionId === item.questionId)
      return <Swiper.Item key={index}>
        <section className="questionItem">
          <div className="questionTitle">
            {index + 1}.
            <Tag color='#64b99c' style={{ borderRadius: 6, margin: "0 4px" }}>{questionTypeData[item.questionType]}</Tag>
            {item.questionType === 4 ? item.question.replace(/{fillBlank_[1-9]}/g, "_____") : item.question}
          </div>
          <div className="answer">
            <QuestionOptions userRecord={recordDetailList[findQue]} type={item.questionType} list={item.examContainQuestionList} throwAnswer={(e) => getAnswers(e, item.questionId)} />
          </div>
        </section>
      </Swiper.Item>
    })}
  </Swiper>
})
export default QuestionList