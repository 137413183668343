import { NavBar, Image, List } from "antd-mobile";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import www from "../../services";
import "./index.scss";

export default function MyInfoPage() {
  const navigate = useNavigate();
  const userData = JSON.parse(localStorage.getItem("user_data"));

  const [userInfo, setUserInfo] = useState([]);

  useEffect(() => {
    const getUserInfo = async () => {
      const params = {
        userId: userData?.userId,
        type: userData?.type,
      };

      const res = await www.get("/userInfo", { params });

      if (res.success) {
        setUserInfo(res.data);
      }
    };

    getUserInfo();
  }, []);

  return (
    <div className="myInfoBox">
      <NavBar onBack={() => navigate(-1)}>我的资料</NavBar>

      <div style={{ padding: "0 6vw" }}>
        <div className="userImgBox">
          <Image
            src={
              userInfo?.photoUrl
                ? userInfo?.photoUrl
                : "https://images.unsplash.com/photo-1493666438817-866a91353ca9?ixlib=rb-0.3.5&q=80&fm=jpg&crop=faces&fit=crop&h=200&w=200&s=b616b2c5b373a80ffc9636ba24f7a4a9"
            }
            fit="cover"
            className="userImg"
          />
        </div>

        <List>
          <List.Item>
            <div className="infoItem">
              <span>姓名</span>
              <span className="text">{userInfo?.name}</span>
            </div>
          </List.Item>
          <List.Item>
            <div className="infoItem">
              <span>性别</span>
              <span className="text">{userInfo?.gender ? "男" : "女"}</span>
            </div>
          </List.Item>
          <List.Item>
            <div className="infoItem">
              <span>手机号</span>
              <span className="text">{userInfo?.phone}</span>
            </div>
          </List.Item>
          <List.Item>
            <div className="infoItem">
              <span>职务</span>
              <span className="text">{userInfo?.position}</span>
            </div>
          </List.Item>
        </List>
      </div>
    </div>
  );
}
