import donghuozuoye from "../assets/donghuozuoye.png";
import gaokongzuoye from "../assets/gaokongzuoye.png";
import shebeijianxiu from "../assets/shebeijianxiu.png";
import shouxiankongjian from "../assets/shouxiankongjian.png";
import mangbanchoudu from "../assets/mangbanchoudu.png";
import diaozhuangzuoye from "../assets/diaozhuangzuoye.png";
import dongtuzuoye from "../assets/dongtuzuoye.png";
import duanluzuoye from "../assets/duanluzuoye.png";

export const zuoyeIcons = {
  "动火作业": donghuozuoye,
  "高处作业": gaokongzuoye,
  "设备检维修作业": shebeijianxiu,
  "受限空间作业": shouxiankongjian,
  "盲板抽堵作业": mangbanchoudu,
  "吊装作业": diaozhuangzuoye,
  "动土作业": dongtuzuoye,
  "断路作业": duanluzuoye,
};
