import { Navigate, useRoutes } from "react-router-dom";
import LoginPage from "../pages/login";
import TabBarPage from "../pages/tabBar";
import HomePage from "../pages/home";
import ExaminationPage from "../pages/examination";
import SafeLearnPage from "../pages/safeLearn";
import UserPage from "../pages/user";
import MyInfoPage from "../pages/myInfo";
import EditPasswordPage from "../pages/editPassword";
import ExamQuestion from "../pages/question";
import ExamResult from "../pages/result";
import TrainingContentDetailPage from "../pages/trainingContentDetail";
import LearnRecordDetailPage from "../pages/learnRecordDetail";

export default function RouteViews() {
  return useRoutes([
    { path: "*", element: <Navigate to="/login" /> },
    { path: "/login", element: <LoginPage /> },
    {
      path: "/tabBar",
      element: <TabBarPage />,
      children: [
        { path: "home", element: <HomePage /> },
        { path: "user", element: <UserPage /> },
      ],
    },
    { path: "/examination", element: <ExaminationPage /> },
    { path: "/safeLearn", element: <SafeLearnPage /> },
    { path: "/myInfo", element: <MyInfoPage /> },
    { path: "/editPassword", element: <EditPasswordPage /> },
    { path: "/examQuestion", element: <ExamQuestion/>},
    { path: "/resultPage", element: <ExamResult/>},
    { path: "/trainingContentDetail", element: <TrainingContentDetailPage /> },
    { path: "/learnRecordDetail", element: <LearnRecordDetailPage /> },
  ]);
}
