import { Card, Image } from "antd-mobile";
import www from "../../services";
import { useEffect, useState } from "react";
import "./examinationRecord.scss";
import hege from "../../assets/hege.png";
import buhege from "../../assets/buhege.png";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";

export default function ExaminationRecord({
  select = []
}) {
  const navigate = useNavigate()
  const [examRecord, setExamRecord] = useState([])
  const userData = JSON.parse(localStorage.getItem("user_data"))
  const getExamRecord = async () => {
    const params = {
      enterpriseId: userData?.enterpriseId,
      userId: userData.userId,
      type: select.join()
    }
    const re = await www.get(`/examRecord/app/examRecord`, { params })
    setExamRecord(re.data)
  }

  const addExamRecord = async (examId) => {
    const { enterpriseId } = userData
    const params = {
      enterpriseId,
      examId
    }
    const re = await www.post("/examRecord", params)
    navigate("/examQuestion", {
      state: {
        examId,
        examRecordId: re.data.id
      }
    })
  }
  const getdaytom = (seconds) => {
    const m = Math.round(seconds / 60)
    return m
  }
  useEffect(() => {
    getExamRecord()
  }, [select])
  return (
    <>
      {examRecord.map((item, index) => (
        <Card className="learnRecord" key={index}>
          <div style={{ display: "flex" }}>
            <Image
              fit='none'
              src={item.pass ? hege : buhege}
              width={40}
            />

            <div className="content">
              <div className="fileName">
                {item.examName}
              </div>
              <div style={{ margin: "4px 0" }}>
                <span>{dayjs(item?.examTime).format("YYYY-MM-DD HH:mm:ss")}</span>
                <span style={{ margin: "0 4px" }}>|</span>
                <span>{item?.duration ? getdaytom(item?.duration) : 0}分</span>
              </div>
              <div>
                <span style={{ marginRight: "10px" }}>
                  {item.endTime ? "完成" : "考试中"}
                </span>
              </div>
            </div>
            <section className="rightOps">
              <div >
                <span className="score">{item.score ?? 0}</span>
                <span>成绩</span>
              </div>
              <span className="line"></span>
              {
                item.endTime
                  ?
                  <div className="amination" onClick={() => addExamRecord(item.examId)}>
                    <span>重新</span>
                    <span>考试</span>
                  </div> : <div className="rexamination" onClick={
                    () => navigate("/examQuestion", {
                      state: {
                        examId: item.examId,
                        examRecordId: item.id,
                        diff: dayjs().diff(dayjs(item.examTime))
                      }
                    })}>
                    <span>继续</span>
                    <span>考试</span>
                  </div>}
            </section>
          </div>
        </Card>
      ))}

      <div style={{ textAlign: "center" }}>没有更多了</div>
    </>
  );
}
