import axios from "axios";

const service = axios.create({
  baseURL: "http://api-gx-park.haoweitech.com.cn/studyApp",
});

// 请求拦截器
service.interceptors.request.use((config) => {
  const headers = config.headers ?? {};
  if (config.data instanceof FormData) {
    headers["Content-Type"] = "multipart/form-data;charset=UTF-8";
  }
  const userData = JSON.parse(localStorage.getItem("user_data"))
  const token = userData?.token;
  if (token) {
    headers["Authorization"] = `Bearer ${token}`;
  }
  return config;
});

export async function getToken(projectName) {
  const res = await service.get("/oss/webOffice/generateWebOfficeToken", {
    params: {
      projectName,
    },
  });
  return res.data;
}
