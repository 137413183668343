import { Checkbox, Grid, Radio, SearchBar, Space } from "antd-mobile"
import { useState } from "react"

const QuestionOptions = ({
  type,
  userRecord,
  list = [],
  throwAnswer
}) => {
  const [fillblankAnswer, setFillblankAnswer] = useState([])
  const onOptionChange = (e) => {
    const answer = type === 1 ? [e] : e
    throwAnswer?.({ type, answer })
  }
  const onInputBlur = (idx, e) => {
    if (!!e.answer) {
      setFillblankAnswer(answer=>{
        const answers = [...answer]
        answers[idx] = e
        throwAnswer?.({ type, answer: answers })
        return answers
      })
    }
  }
  const templateFn = () => {
    let templateOptions;
    switch (type) {
      case 1:
        const radioRecordAnswerIndex = list.findIndex(item => item.sort == userRecord?.userAnswer)
        templateOptions = <Radio.Group defaultValue={list[radioRecordAnswerIndex]?.id} onChange={onOptionChange}>
          <Space style={{ width: "100%" }} direction='vertical'>
            {list?.map(item => {
              return <Radio style={{
                '--icon-size': '18px',
                '--font-size': '14px',
                '--gap': '6px',
                margin: 4
              }} value={item.id} block>{String.fromCharCode(item.sort)} {item.description}</Radio>
            })}
          </Space>
        </Radio.Group>
        break;

      case 2:
        const checkRecordAnswerIndex = list.findIndex(item => item.sort == userRecord?.userAnswer)
        templateOptions = <Checkbox.Group defaultValue={[list[checkRecordAnswerIndex]?.id]} onChange={onOptionChange}>
          <Space style={{ width: "100%" }} direction='vertical'>
            {list?.map(item => {
              return <Checkbox style={{
                '--icon-size': '18px',
                '--font-size': '14px',
                '--gap': '6px',
                margin: 4
              }} value={item.id} block>{String.fromCharCode(item.sort)} {item.description}</Checkbox>
            })}
          </Space>
        </Checkbox.Group>
        break;
      case 3:
        templateOptions = <Radio.Group defaultValue={userRecord?.userAnswer} onChange={onOptionChange}>
          <Space style={{ width: "100%" }} direction='vertical'>
            {[
              { value: '1', description: "正确" },
              { value: '2', description: "错误" }
            ]?.map((item, idx) => {
              return <Radio style={{
                '--icon-size': '18px',
                '--font-size': '14px',
                '--gap': '6px',
                margin: 4
              }} value={item.value} block>{item.description}</Radio>
            })}
          </Space>
        </Radio.Group>
        break;
      case 4:
        templateOptions = <Space style={{ width: "100%", '--gap': '16px' }} direction='vertical'>
          {list?.map((item, idx) => {
            let answerList = []
            if(!!userRecord?.userAnswer) {
              answerList = userRecord?.userAnswer.split()
            }
            return <Grid columns={9} gap={8}>
              <Grid.Item style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                {idx + 1}.
              </Grid.Item>
              <Grid.Item span={8}>
                <SearchBar icon={false} defaultValue={answerList[idx]} onBlur={(e) => onInputBlur(idx, {answer: e.target.value, id: item.id})} placeholder='请填写内容' />
              </Grid.Item>
            </Grid>
          })}

        </Space>

        break;
    }
    return templateOptions
  }

  return templateFn()
}

export default QuestionOptions