import { NavBar, Card, CapsuleTabs } from "antd-mobile";
import { useNavigate } from "react-router-dom";
import ExaminationItem from "./examinationItem";
import ExaminationRecord from "./examinationRecord";
import "./index.scss";
import www from "../../services";
import { useState, useEffect } from "react";
import SelectBtn from "../../components/SelectBtn";

export default function ExaminationPage() {
  const navigate = useNavigate();
  const [selectKeys, setSelectKeys] = useState([])
  const [examStatus, setExamStatus] = useState({})
  const handleValue = (value) => {
    setSelectKeys(value)
  };
  const getExamStatus = async () => {
    const userData = JSON.parse(localStorage.getItem("user_data"))
    const params = {
      enterpriseId: userData?.enterpriseId,
      userId: userData?.userId,
      type: selectKeys.join()
    }
    const re = await www.get(`/examRecord/app/examStatus`, { params })
    setExamStatus(re.data)
  }
  useEffect(() => {
    getExamStatus()
  }, [selectKeys])
  return (
    <>
      <NavBar
        style={{ color: "white" }}
        onBack={() => navigate("/tabBar/home")}
        right={<SelectBtn onSelectChange={handleValue} />}
      >
        安全考试
      </NavBar>

      <div className="recordBox">
        <div className="recordItem">
          <span>{examStatus?.examCount}</span>
          <span>考试次数</span>
        </div>
        <div
          className="recordItem"
          style={{ fontSize: "30px", margin: "0 15vw" }}
        >
          |
        </div>
        <div className="recordItem">
          <span>{examStatus?.examTime}h</span>
          <span>考试用时</span>
        </div>
      </div>

      <Card>
        <CapsuleTabs>
          <CapsuleTabs.Tab destroyOnClose title="考试内容" key="1">
            <ExaminationItem select={selectKeys} />
          </CapsuleTabs.Tab>
          <CapsuleTabs.Tab destroyOnClose title="考试记录" key="2">
            <ExaminationRecord select={selectKeys}/>
          </CapsuleTabs.Tab>
        </CapsuleTabs>
      </Card>
    </>
  );
}
