import React, { useEffect, useState } from 'react'
import { ResultPage, Card, NavBar } from 'antd-mobile'
import "./index.scss"
import www from '../../services'
import { useLocation, useNavigate } from 'react-router-dom'

const ExamResult = () => {
  const navigate = useNavigate()
  const [resultData, setResultData] = useState()
  const { state } = useLocation()

  const getResult = async () => {
    const result = await www.get(`/examRecord/${state?.id}`)
    setResultData(result.data)
  }
  useEffect(() => {
    getResult()
  }, [])
  return (
    <>
      <NavBar
        className={resultData?.pass === 0 ? 'errorNav': 'sueccssNav' }
        // onBack={() => navigate("/examination", { replace: true })}
        backArrow={false}
        style={{ color: "white" }}
      >
        考试结果
      </NavBar>
      <ResultPage
        className={resultData?.pass === 0 ? 'error' : 'sueccss'}
        status={resultData?.pass == 1 ? 'success' : 'error'}
        title={`${resultData?.score ?? 0}分`}
        description={resultData?.pass == 1 ? '恭喜你，成绩合格' : '很遗憾，考试成绩不合格'}
        secondaryButtonText='返回'
        onSecondaryButtonClick={() => navigate("/examination", { replace: true })}
      >

        <ResultPage.Card style={{ height: 300, padding: 8 }}>

        </ResultPage.Card>
      </ResultPage>
    </>

  )
}

export default ExamResult