import { Card, ProgressBar } from "antd-mobile";
import { ContentOutline } from "antd-mobile-icons";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import www from "../../services";
import "./learnRecord.scss";

export default function LearnRecord({ selectKeys = [] }) {
  const navigate = useNavigate();
  const userData = JSON.parse(localStorage.getItem("user_data"));

  const [learnRecordData, setLearnRecordData] = useState([]);

  const getLearnRecord = async () => {
    const params = {
      enterpriseId: userData?.enterpriseId,
      userId: userData?.userId,
      type: selectKeys.join(","),
    };

    const res = await www.get("/studyRecord/app/learningRecord", { params });

    if (res.success) {
      setLearnRecordData(res.data);
    }
  };

  useEffect(() => {
    getLearnRecord();
  }, [selectKeys]);

  return (
    <>
      {learnRecordData.map((item, index) => (
        <Card
          className="learnRecord"
          key={index}
          onClick={() =>
            navigate("/learnRecordDetail", {
              state: { item: item },
            })
          }
        >
          <div style={{ display: "flex" }}>
            <ContentOutline fontSize={28} />

            <div className="content">
              <div className="fileName">{item.materialsName}</div>
              <div style={{ margin: "4px 0" }}>
                已学习{item.studyTime ? (item.studyTime / 60 / 60).toFixed(2) : 0}学时
              </div>
              <div>
                <ProgressBar percent={item?.progress} text />
              </div>
            </div>

            <span className="time">继续学习</span>
          </div>
        </Card>
      ))}

      <div style={{ textAlign: "center" }}>没有更多了</div>
    </>
  );
}
