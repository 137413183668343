export default function ViewVideo({ attachmentUrl = "" }) {
  return (
    <>
      <video
        width={window.innerWidth}
        controls
        src={attachmentUrl}
        autoPlay
      ></video>
    </>
  );
}
