import { Popup, CheckList } from "antd-mobile";
import { FilterOutline } from "antd-mobile-icons";
import { useState } from "react";
import "./index.scss";

export default function SelectBtn(props) {
  const [visible, setVisible] = useState(false);

  const [columns, setColumns] = useState([
    { label: "吊装作业", value: "1" },
    { label: "设备检维修作业", value: "2" },
    { label: "动土作业", value: "3" },
    { label: "受限空间作业", value: "4" },
    { label: "高处作业", value: "5" },
    { label: "动火作业", value: "6" },
    { label: "盲板抽堵作业", value: "7" },
    { label: "断路作业", value: "8" },
  ]);
  const [selected, setSelected] = useState([]);

  const confirmSelect = () => {
    props.onSelectChange(selected);
    setSelected([]);
    setVisible(false);
  };

  return (
    <>
      <FilterOutline
        style={{ fontSize: 24 }}
        onClick={() => setVisible(true)}
      />

      <Popup
        visible={visible}
        onMaskClick={() => {
          setVisible(false);
        }}
        destroyOnClose
        bodyStyle={{
          minHeight: "40vh",
          borderTopLeftRadius: "20px",
          borderTopRightRadius: "20px",
        }}
      >
        <div style={{ padding: "18px 10px" }}>
          <div className="popupBtn">
            <span onClick={() => setVisible(false)}>取消</span>
            <span>选择类型</span>
            <span onClick={() => confirmSelect()}>确定</span>
          </div>

          <div className="checkListContainer">
            <CheckList
              className="checkList"
              multiple
              onChange={(val) => {
                setSelected(val);
              }}
            >
              {columns.map((item) => (
                <CheckList.Item key={item.value} value={item.value}>
                  {item.label}
                </CheckList.Item>
              ))}
            </CheckList>
          </div>
        </div>
      </Popup>
    </>
  );
}
