import { Form, Button, Input, Popup, CheckList, Toast } from "antd-mobile";
import { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import www from "../../services";
import "./index.scss";

export default function LoginPage() {
  const navigate = useNavigate();

  const [form] = Form.useForm();
  const formRef = useRef(null);

  const [visible, setVisible] = useState(false);
  const [enterpriseList, setEnterpriseList] = useState([]);

  const onFinish = async (values) => {
    const res = await www.post(
      "http://api-gx-park.haoweitech.com.cn/studyAppLogin",
      values
    );

    if (res.success) {
      localStorage.setItem(
        "user_data",
        JSON.stringify({ ...res.data, enterpriseId: values.enterpriseId })
      );
      Toast.show({
        content: "登录成功",
      });
      setTimeout(() => {
        navigate("/tabBar/home");
      }, 500);
    }
  };

  const getEnterprise = async () => {
    const res = await www.get("/enterprise");

    if (res.success) {
      const dataArray = res.data;

      setEnterpriseList(
        dataArray.map((v) => ({
          value: v.id,
          label: v.name,
        }))
      );
    }
  };

  useEffect(() => {
    getEnterprise();
  }, []);

  return (
    <div className="loginBox">
      <div className="title">
        <div className="logIn">登录</div>
        <div className="text">安全培训考试</div>
      </div>

      <div className="form">
        <Form
          ref={formRef}
          form={form}
          layout="horizontal"
          onFinish={onFinish}
          footer={
            <Button
              block
              type="submit"
              color="primary"
              size="large"
              onClick={() => formRef.current.submit()}
            >
              登录
            </Button>
          }
        >
          <Form.Item
            name="enterpriseName"
            rules={[{ required: true, message: "请选择" }]}
          >
            <Input
              placeholder="请选择"
              onClick={() => {
                document.activeElement.blur();
                setVisible(true);
              }}
            />
          </Form.Item>
          <Form.Item name="enterpriseId" hidden></Form.Item>
          <Form.Item
            name="username"
            rules={[{ required: true, message: "账号不能为空" }]}
          >
            <Input placeholder="请输入账号" />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[{ required: true, message: "密码不能为空" }]}
          >
            <Input type="password" placeholder="请输入密码" />
          </Form.Item>
        </Form>
      </div>

      <Popup
        visible={visible}
        onMaskClick={() => {
          setVisible(false);
        }}
        destroyOnClose
        bodyStyle={{
          minHeight: "40vh",
          borderTopLeftRadius: "20px",
          borderTopRightRadius: "20px",
        }}
      >
        <div style={{ padding: "18px 10px" }}>
          <div className="checkListContainer">
            <CheckList
              className="checkList"
              multiple={false}
              onChange={(val) => {
                const foundItemName = enterpriseList.find(
                  (item) => item.value == val[0]
                );

                form.setFieldValue("enterpriseId", val[0]);
                form.setFieldValue("enterpriseName", foundItemName?.label);
                setVisible(false);
              }}
            >
              {enterpriseList.map((item) => (
                <CheckList.Item key={item.value} value={item.value}>
                  {item.label}
                </CheckList.Item>
              ))}
            </CheckList>
          </div>
        </div>
      </Popup>
    </div>
  );
}
