import { Card, Image } from "antd-mobile";
import { TextOutline, EyeOutline, FileOutline } from "antd-mobile-icons";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import www from "../../services";
import "./trainingContent.scss";
import { zuoyeIcons } from "../../components/iconImg";

export default function TrainingContent({ selectKeys = [] }) {
  const navigate = useNavigate();
  const userData = JSON.parse(localStorage.getItem("user_data"));

  const [trainingContentData, setTrainingContentData] = useState([]);

  const getTrainingContent = async () => {
    const params = {
      enterpriseId: userData?.enterpriseId,
      userId: userData?.userId,
      type: selectKeys.join(","),
    };

    const res = await www.get("/studyRecord/app/trainingContent", { params });

    if (res.success) {
      setTrainingContentData(res.data);
    }
  };

  useEffect(() => {
    getTrainingContent();
  }, [selectKeys]);

  return (
    <>
      {trainingContentData.map((item, index) => (
        <Card
          className="trainingContent"
          key={index}
          onClick={() =>
            navigate("/trainingContentDetail", {
              state: { item: item },
            })
          }
        >
          <div style={{ display: "flex" }}>
            <Image
              src={zuoyeIcons[item.specialWorkType]}
              fit="cover"
              className="imgItem"
            />

            <div className="content">
              <div className="fileName">
                <TextOutline style={{ marginRight: "6px" }} />
                <span>{item.materialsName}</span>
              </div>
              <div style={{ margin: "4px 0" }}>
                <span>类型: {item.materialsType}</span>
                <span style={{ margin: "0 4px" }}>|</span>
                <span>大小: {Number(item.materialsSize).toFixed(2)}M</span>
              </div>
              <div>
                <span style={{ marginRight: "10px" }}>
                  <EyeOutline />
                  {item.resourceViews}
                </span>
                <span>
                  <FileOutline />
                  {item.resourceReaders}
                </span>
              </div>
            </div>

            <span className="time">
              学时{item.studyTime ? (item.studyTime / 60 / 60).toFixed(2) : 0}
            </span>
          </div>
        </Card>
      ))}

      <div style={{ textAlign: "center" }}>没有更多了</div>
    </>
  );
}
