import { Toast } from "antd-mobile";
import axios from "axios";

const service = axios.create({
  baseURL: "http://api-gx-park.haoweitech.com.cn/studyApp",
  timeout: 50000,
});

// 请求拦截器
service.interceptors.request.use((config) => {
  const headers = config.headers ?? {};
  if (config.data instanceof FormData) {
    headers["Content-Type"] = "multipart/form-data;charset=UTF-8";
  }
  const userData = JSON.parse(localStorage.getItem("user_data"))
  const token = userData?.token;
  if (token) {
    headers["Authorization"] = `Bearer ${token}`;
  }
  return config;
});

// 响应拦截器
service.interceptors.response.use(
  (response) => {
    const data = response.data;
    if (toString.call(response.data) === "[object Blob]") {
      Toast.show({
        icon: "success",
        content: data.message ?? "操作成功",
      });
      return response;
    }
    if (data.success) {
      // if (response.config.method !== 'get') Toast.show({
      //   icon: 'success',
      //   content: data.message ?? "操作成功",
      // })
    } else {
      Toast.show({
        icon: "fail",
        content: data.message,
      });
    }
    return data;
  },
  (error) => {
    if (error.response?.status === 401) {
      // history.push('/login')
    } else {
      Toast.show({
        icon: "fail",
        content:
          error.response?.data.message ??
          "请求超时或服务器异常，请检查网络或联系管理员",
      });
    }
    return Promise.resolve(error);
  }
);

export default service;

export class HttpConfig {
  config;

  constructor(config) {
    this.config = config;
  }
}

const getHandler = (instance, _) => {
  const handler = {
    get: function get(target, prop) {
      let value;
      const item = Reflect.get(target, prop);
      const httpMethod = Reflect.get(instance, prop);

      const methods = {
        params: (arg1, arg2) => {
          return arg1 instanceof HttpConfig
            ? httpMethod(target.url, arg1?.config)
            : httpMethod(target.url, {
                params: Object.fromEntries(
                  Object.entries(arg1 ?? {}).map((item) => {
                    if (!(item[1] instanceof Object)) {
                      return item;
                    } else {
                      return [];
                    }
                  })
                ),
                data: arg1,
                ...arg2,
              });
        },
        data: (arg1, arg2) => {
          return arg1 instanceof HttpConfig
            ? httpMethod(target.url, arg1?.config)
            : httpMethod(target.url, arg1, arg2?.config);
        },
      };

      switch (prop) {
        case "get":
        case "delete":
          value = methods.params;
          break;
        case "post":
        case "put":
          value = methods.data;
          break;
        case "url":
          value = item;
          break;
        default:
          value = {
            ...item,
            url: `${target.url ?? ""}/${prop.toString()}`,
          };
          break;
      }

      if (!item || typeof item !== "function") Reflect.set(target, prop, value);
      return new Proxy(Reflect.get(target, prop), { get });
    },
  };

  return handler;
};

export const www = new Proxy({}, getHandler(service, {}));
