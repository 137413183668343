const questionTypeData = {
  1: "单选",
  2: "多选",
  3: "判断",
  4: "填空"
};

const questionTypeOptions = Object.entries(questionTypeData).map(([key, value]) => ({
  label: value,
  value: parseInt(key),
}));

export {questionTypeData, questionTypeOptions};