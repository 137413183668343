import { NavBar, Card, CapsuleTabs } from "antd-mobile";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import www from "../../services";
import SelectBtn from "../../components/SelectBtn";
import TrainingContent from "./trainingContent";
import LearnRecord from "./learnRecord";
import "./index.scss";

export default function SafeLearnPage() {
  const navigate = useNavigate();
  const userData = JSON.parse(localStorage.getItem("user_data"));

  const [selectKeys, setSelectKeys] = useState([]);
  const [styleStatusData, setStyleStatusData] = useState();

  const handleValue = (value) => {
    setSelectKeys(value);
  };

  const getStyleStatus = async () => {
    const params = {
      enterpriseId: userData?.enterpriseId,
      userId: userData?.userId,
      type: selectKeys.join(","),
    };

    const res = await www.get("/studyRecord/app/styleStatus", { params });

    if (res.success) {
      setStyleStatusData(res.data);
    }
  };

  useEffect(() => {
    getStyleStatus();
  }, [selectKeys]);

  return (
    <>
      <NavBar
        style={{ color: "white" }}
        onBack={() => navigate(-1)}
        right={<SelectBtn onSelectChange={handleValue} />}
      >
        安全学习
      </NavBar>

      <div className="recordBox">
        <div className="recordItem">
          <span>
            {styleStatusData?.todayStudyTime
              ? (styleStatusData.todayStudyTime / 60 / 60).toFixed(2)
              : 0}
            h
          </span>
          <span>今日学习</span>
        </div>
        <div
          className="recordItem"
          style={{ fontSize: "30px", margin: "0 15vw" }}
        >
          |
        </div>
        <div className="recordItem">
          <span>
            {styleStatusData?.totalStudyTime
              ? (styleStatusData.totalStudyTime / 60 / 60).toFixed(2)
              : 0}
            h
          </span>
          <span>累计学时</span>
        </div>
      </div>

      <Card>
        <CapsuleTabs>
          <CapsuleTabs.Tab title="培训内容" key="1" destroyOnClose>
            <TrainingContent selectKeys={selectKeys} />
          </CapsuleTabs.Tab>
          <CapsuleTabs.Tab title="学习记录" key="2" destroyOnClose>
            <LearnRecord selectKeys={selectKeys} />
          </CapsuleTabs.Tab>
        </CapsuleTabs>
      </Card>
    </>
  );
}
