import { DotLoading, Mask } from "antd-mobile"
import { useEffect, useState } from "react";
import "./index.scss";

const MaskLoading = ({
  content = "加载中",
  canClose = true,
  loading = false
}) => {
  const [visible, setVisible] = useState(loading)
  const onMaskClick = () => {
    if (canClose) {
      setVisible(false)
    }
  }
  useEffect(() => {
    if (!loading) {
      setTimeout(() => {
        setVisible(loading)
      }, 600);
    } else {
      setVisible(loading)
    }
  }, [loading])
  return (
    <>
      <Mask destroyOnClose visible={visible} onMaskClick={onMaskClick}>
        <div className="overlayContent">
          {content}
          <DotLoading />
        </div>
      </Mask>
    </>
  )
}

export default MaskLoading
