import { NavBar, Toast } from "antd-mobile";
import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import dayjs from "dayjs";
import www from "../../services";
import ViewFile from "../../components/ViewFile";
import ViewVideo from "../../components/ViewVideo";
import "./index.scss";

export default function TrainingContentDetailPage() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const userData = JSON.parse(localStorage.getItem("user_data"));

  const [attachmentUrl, setAttachmentUrl] = useState();
  const [timeStr, setTimeStr] = useState();

  useEffect(() => {
    let countdownInterval = null;

    const startCountdown = (timer) => {
      if (timer < 0) {
        setTimeStr("00时00分00秒");
        return;
      }

      countdownInterval = setInterval(() => {
        const hours = Math.floor(timer / 3600);
        const minutes = Math.floor((timer % 3600) / 60);
        const seconds = timer % 60;

        setTimeStr(
          `${hours.toString().padStart(2, "0")}时${minutes
            .toString()
            .padStart(2, "0")}分${seconds.toString().padStart(2, "0")}秒`
        );

        if (--timer < 0) {
          setTimeStr("00时00分00秒");
          clearInterval(countdownInterval);
          Toast.show({
            content: "完成学习",
          });
        }
      }, 1000);
    };

    const getTime = async () => {
      if (state.item?.readRecordId) {
        const res = await www.get(`/studyRecord/${state.item.readRecordId}`);

        if (res.success) {
          startCountdown(state.item?.studyTime - res.data.studyTime);
        }
      } else {
        startCountdown(state.item?.studyTime);
      }
    };

    getTime();

    return () => {
      clearInterval(countdownInterval);
    };
  }, [state.item]);

  useEffect(() => {
    let timer = null;

    const continueStduy = (id) => {
      timer = setInterval(async () => {
        const data = {
          recordId: id,
          startTime: dayjs().valueOf() - 5000,
          endTime: dayjs().valueOf(),
        };

        await www.post("/studyRecordDetail", data);
      }, 5000);
    };

    const studying = async () => {
      if (state.item?.readRecordId) {
        continueStduy(state.item.readRecordId);
      } else {
        const data = {
          enterpriseId: userData?.enterpriseId,
          materialId: state.item?.materialsId,
          userId: userData?.userId,
          unitId: userData?.unitId,
          type: userData?.type,
        };

        const res = await www.post("/studyRecord", data);

        if (res.success) {
          continueStduy(res?.data?.id);
        }
      }
    };

    studying();

    return () => {
      clearInterval(timer);
    };
  }, []);

  useEffect(() => {
    const getUrl = async () => {
      if (state.item?.materialsId) {
        const res = await www.get(`/materials/${state.item.materialsId}`);

        if (res.success) {
          setAttachmentUrl(res.data.attachmentUrl);
        }
      }
    };

    getUrl();
  }, [state.item]);

  return (
    <div className="safeLearnDetailBox">
      <NavBar className="navBarBox" onBack={() => navigate(-1)}>
        {state?.item?.materialsName}
      </NavBar>

      <div className="time">
        <div className="title">学时</div>
        <div className="text">{timeStr}</div>
      </div>

      <div className="view">
        {["ppt", "pptx", "pdf"].includes(state?.item?.materialsType) ? (
          <ViewFile attachmentUrl={attachmentUrl} />
        ) : (
          ""
        )}

        {["mp4", "avi", "mov"].includes(state?.item?.materialsType) ? (
          <ViewVideo attachmentUrl={attachmentUrl} />
        ) : (
          ""
        )}
      </div>
    </div>
  );
}
