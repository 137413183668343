import { Grid, Modal } from "antd-mobile"
import { useState } from "react"
import "./index.scss"
import www from "../../services"
import dayjs from "dayjs"
import { useNavigate } from "react-router-dom"
import MaskLoading from "../../components/maskLoading"
const SubmitExamModal = ({
  time,
  trigger,
  confrim,
  recordId,
  unanswered
}) => {
  const [visible, setVisible] = useState(false)
  const navigate = useNavigate()
  const onModalClose = () => {
    setVisible(false)
  }
  const onModalConfrim = async () => {
    setVisible(false)
    await confrim?.()
    recordSubmitTime()
  }
  const recordSubmitTime = async () => {
    const re = await www.put(`/examRecord/${recordId}`, { endTime: dayjs().format("YYYY-MM-DD HH:mm:ss") })
    if (re.success) {
      navigate("/resultPage", { state: { id: recordId } })
    }
  }
  return (
    <>
      <span
        block
        onClick={() => {
          setVisible(true)
        }}
      >
        {trigger}
      </span>
      <Modal
        visible={visible}
        content={<section className="modalContent">
          <p>确认提交</p>
          <Grid columns={2} gap={16}>
            <Grid.Item>
              <div className="showItem">
                <span>{time}</span>
                <span>剩余时间</span>
              </div>
            </Grid.Item>
            <Grid.Item>
              <div className="showItem">
                <span>{unanswered}</span>
                <span>未答题</span>
              </div>
            </Grid.Item>
          </Grid>
          <div className="contentFooter">
            <span className="footerbtn" onClick={onModalClose}>继续考试</span>
            <span className="footerbtn" onClick={onModalConfrim}>确认提交</span>
          </div>
        </section>}
        closeOnAction
        onClose={() => {
          setVisible(false)
        }}
      />
    </>
  )
}

export default SubmitExamModal