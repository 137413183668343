import { Card, NavBar } from "antd-mobile";
import { useNavigate } from "react-router-dom";
import "./index.scss";
import safeLearnImg from "../../assets/safeLearn.png";
import examinationImg from "../../assets/examination.png";

function HomePage() {
  const navigate = useNavigate();

  return (
    <section className="HomeContent">
      <NavBar backArrow={false} style={{ color: "white" }}>
        安全培训考试
      </NavBar>

      <Card
        headerStyle={{ border: 0 }}
        style={{ marginTop: "20px" }}
        onClick={() => navigate("/safeLearn")}
      >
        <div className="CardStyle">
          <div className="CardLeft">
            <div className="title">安全学习</div>
            <div className="text">点击进入 &gt;</div>
          </div>
          <div className="CardRight">
            <img src={safeLearnImg} width={60} />
          </div>
        </div>
      </Card>
      <Card
        headerStyle={{ border: 0 }}
        style={{ marginTop: "20px" }}
        onClick={() => navigate("/examination")}
      >
        <div className="CardStyle">
          <div className="CardLeft">
            <div className="title">安全考试</div>
            <div className="text">点击进入 &gt;</div>
          </div>
          <div className="CardRight">
            <img src={examinationImg} width={60} />
          </div>
        </div>
      </Card>
    </section>
  );
}

export default HomePage;
